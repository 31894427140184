import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

import useActions from "../redux/useActions";

import { RootState } from "../redux/store";

type Gender = "Man" | "Woman" | "Both";

import {
  fetchResults,
  setSelectedMinAge,
  setSelectedMaxAge,
  setSelectedGenderName,
  setDistance,
  setFilteredSearch,
  setLocation,
  setSortBy,
  resetFilters,
  State,
  LocationType,
  setSelectedPlaceName,
  resetIndex,
  setLocationFromGoogleApi,
} from "../redux/slices/searchSlice";

export type { LocationType };

import { Member } from "../backendProxies";
import { LocationHelperPlace } from "../backendAPI/General";

type UseSearch = () => State & {
  resetIndex: () => void;
  fetchResults: (shouldReset: boolean) => void;
  setSelectedMinAge: (age: number) => void;
  setSelectedMaxAge: (age: number) => void;
  setSelectedGenderName: (name: string) => void;
  setDistance: (distance: number) => void;
  setFilteredSearch: (filteredSearchObjectToMerge: any) => void;
  setSortBy: (sortBy: string) => void;
  resetFilters: () => void;
  setSelectedPlaceName: (placeName: string) => void;
  setLocation: (location: LocationHelperPlace) => void;
  setLocationFromGoogleApi: (location: any) => void;
  nbAdvancedFilters: number;

  reset: () => void;
  isLoading: boolean;
  hasMore: boolean;
  members: Member[];
  location: {
    type: LocationType;
    id: string;
  } | null;

  filteredSearch: {
    selectedGenderName: Gender;
    selectedMinAge: number;
    selectedMaxAge: number;
    distance: number;
    hasPictureOnly: number;
    isCertifiedOnly: number;
    relationshipType: string[];
    tailleId: string[];
    AppPhysiqueId: string[];
    // cheveuxId: string[];
    // yeuxId: string[];
    ethnieId: string[];
    signeZodiaqueId: string[];
    etatCivileId: string[];
    enfantActuelId: string[];
    // enfantDesiresId: string[];
    // occupationId: string[];
    // voitureId: string[];
    fumeurId: string[];
    // alcoolId: string[];
    // drogueId: string[];
    lastVisitedDate: number;
  };
};

const useSearch: UseSearch = () => {
  const selectResults = (state: RootState) => state.search.results;

  const location = useSelector((state: RootState) => state.search.location);

  const fetchingResults = useSelector(
    (state: RootState) => state.search.fetchingResults
  );

  const selectedPlaceName = useSelector(
    (state: RootState) => state.search.selectedPlaceName
  );

  const selectMembers = createSelector([selectResults], (results) => {
    if (!results || results.length === 0) return [];

    return results.map((result) => new Member(result));
  });

  const members = useSelector(selectMembers);

  const hasMore = useSelector((state: RootState) => state.search.hasMore);

  const isLoading = useSelector((state: RootState) => state.search.isLoading);

  const selectedMinAge = useSelector(
    (state: RootState) => state.search.selectedMinAge
  );

  const selectedGenderName = useSelector(
    (state: RootState) => state.search.selectedGenderName
  );

  const selectedMaxAge = useSelector(
    (state: RootState) => state.search.selectedMaxAge
  );

  const distance = useSelector((state: RootState) => state.search.distance);

  const filteredSearch = useSelector(
    (state: RootState) => state.search.filteredSearch
  );

  const sortBy = useSelector((state: RootState) => state.search.sortBy);

  const shouldReload = useSelector(
    (state: RootState) => state.search.shouldReload
  );

  const shouldShowSlider = useSelector(
    (state: RootState) => state.search.shouldShowSlider
  );

  const preferencesLoaded = useSelector(
    (state: RootState) => state.search.preferencesLoaded
  );

  const nbAdvancedFilters = useSelector((state: RootState) => {
    let count = 0;
    if (
      state.search.filteredSearch.AppPhysiqueId.length > 0 &&
      state.search.filteredSearch.AppPhysiqueId[0].toString() !== "-2"
    )
      count++;
    if (
      state.search.filteredSearch.relationshipType.length > 0 &&
      state.search.filteredSearch.relationshipType[0].toString() !== "-2"
    )
      count++;
    // if (
    //   state.search.filteredSearch.cheveuxId.length > 0 &&
    //   state.search.filteredSearch.cheveuxId[0].toString() !== "-2"
    // )
    //   count++;
    // if (
    //   state.search.filteredSearch.yeuxId.length > 0 &&
    //   state.search.filteredSearch.yeuxId[0].toString() !== "-2"
    // )
    //   count++;
    if (
      state.search.filteredSearch.ethnieId.length > 0 &&
      state.search.filteredSearch.ethnieId[0].toString() !== "-2"
    )
      count++;
    if (
      state.search.filteredSearch.signeZodiaqueId.length > 0 &&
      state.search.filteredSearch.signeZodiaqueId[0].toString() !== "-2"
    )
      count++;
    if (
      state.search.filteredSearch.etatCivileId.length > 0 &&
      state.search.filteredSearch.etatCivileId[0].toString() !== "-2"
    )
      count++;
    if (
      state.search.filteredSearch.enfantActuelId.length > 0 &&
      state.search.filteredSearch.enfantActuelId[0].toString() !== "-2"
    )
      count++;
    // if (
    //   state.search.filteredSearch.enfantDesiresId.length > 0 &&
    //   state.search.filteredSearch.enfantDesiresId[0].toString() !== "-2"
    // )
    //   count++;
    // if (
    //   state.search.filteredSearch.occupationId.length > 0 &&
    //   state.search.filteredSearch.occupationId[0].toString() !== "-2"
    // )
    //   count++;
    // if (
    //   state.search.filteredSearch.voitureId.length > 0 &&
    //   state.search.filteredSearch.voitureId[0].toString() !== "-2"
    // )
    //   count++;
    if (
      state.search.filteredSearch.fumeurId.length > 0 &&
      state.search.filteredSearch.fumeurId[0].toString() !== "-2"
    )
      count++;
    // if (
    //   state.search.filteredSearch.alcoolId.length > 0 &&
    //   state.search.filteredSearch.alcoolId[0].toString() !== "-2"
    // )
    //   count++;
    // if (
    //   state.search.filteredSearch.drogueId.length > 0 &&
    //   state.search.filteredSearch.drogueId[0].toString() !== "-2"
    // )
    //   count++;
    if (
      state.search.filteredSearch.religionId.length > 0 &&
      state.search.filteredSearch.religionId[0].toString() !== "-2"
    )
      count++;
    return count;
  });

  const actions = useActions({
    fetchResults,
    setSelectedMinAge,
    setSelectedMaxAge,
    setSelectedGenderName,
    setDistance,
    setFilteredSearch,
    setSortBy,
    resetFilters,
    setLocation,
    setSelectedPlaceName,
    resetIndex,
    setLocationFromGoogleApi,
  });

  return {
    members,
    selectedPlaceName,
    hasMore,
    isLoading,
    selectedMinAge,
    selectedMaxAge,
    selectedGenderName,
    distance,
    filteredSearch,
    sortBy,
    shouldReload,
    location,
    fetchingResults,
    shouldShowSlider,
    preferencesLoaded,
    nbAdvancedFilters,
    ...actions,
  };
};

export default useSearch;
