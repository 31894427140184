import { RootState } from "../store";

import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../backendAPI";

import { checkIfHasMore } from "../utils";

import getGenderId from "../../utils/getGenderId";

import { logout, login, getLoginInfo } from "./authSlice";

import * as Sentry from "@sentry/browser";

import { searchResultsChangedSubject$ } from "../../context/NavigationContext";
import { LocationHelperPlace } from "../../backendAPI/General";

export interface State {
  results: any[];
  pageIndex: number;
  hasMore: boolean;
  isLoading: boolean;
  error: Error | null;
  location: LocationHelperPlace | null;
  type: "update" | "read";
  sortBy: "all" | "mostpopular" | "newest";
  selectedGenderName: Gender;
  selectedMinAge: number;
  selectedMaxAge: number;
  distance: number;
  filteredSearch: FilteredSearch;
  shouldReload: boolean;
  selectedPlaceName: string;
  fetchingResults: boolean;
  shouldShowSlider: boolean;
  preferencesLoaded: boolean;
}

export type LocationType = "country" | "region" | "city";

export type FilteredSearch = {
  hasPictureOnly: number;
  isCertifiedOnly: number;
  lat: number;
  lng: number;
  lastVisitedDate: number;

  minHeight: number;
  maxHeight: number;

  relationshipType: (string | number)[];
  AppPhysiqueId: string[];
  // cheveuxId: string[];
  // yeuxId: string[];
  ethnieId: string[];
  signeZodiaqueId: string[];
  etatCivileId: string[];
  enfantActuelId: string[];
  // enfantDesiresId: string[];
  // occupationId: string[];
  // voitureId: string[];
  fumeurId: string[];
  // alcoolId: string[];
  // drogueId: string[];
  religionId: string[];
};

type Gender = "Man" | "Woman" | "Both";

const initialState: State = {
  results: [],
  pageIndex: 1,
  hasMore: false,
  isLoading: false,
  error: null,
  type: "read",
  sortBy: "all",
  location: null,
  shouldShowSlider: true,

  selectedGenderName: "Woman",

  selectedMinAge: 18,

  selectedMaxAge: 45,

  distance: 5,
  shouldReload: false,
  selectedPlaceName: "",
  fetchingResults: false,
  preferencesLoaded: false,

  filteredSearch: {
    hasPictureOnly: 0,
    isCertifiedOnly: 0,
    lat: -1,
    lng: -1,
    lastVisitedDate: 0,

    minHeight: 1,
    maxHeight: 26,
    relationshipType: ["-2"],
    AppPhysiqueId: ["-2"],
    // cheveuxId: ["-2"],
    // yeuxId: ["-2"],
    ethnieId: ["-2"],
    signeZodiaqueId: ["-2"],
    etatCivileId: ["-2"],
    enfantActuelId: ["-2"],
    // enfantDesiresId: ["-2"],
    // occupationId: ["-2"],
    // voitureId: ["-2"],
    fumeurId: ["-2"],
    // alcoolId: ["-2"],
    // drogueId: ["-2"],
    religionId: ["-2"],
  },
};

export const fetchResults = createAsyncThunk(
  "search/fetchResults",

  async (shouldReset: boolean = false, { getState }) => {
    const state = getState() as RootState;

    if (state.search.location === null) return;

    const pageIndex = shouldReset ? 1 : state.search.pageIndex;

    const selectedGenderId = getGenderId(state.search.selectedGenderName);

    const config = {
      pageIndex,
      pageSize: 32,
      sortBy: state.search.sortBy,
      type: shouldReset ? "update" : state.search.type,
      ageMin: state.search.selectedMinAge,
      ageMax: state.search.selectedMaxAge,
      distance: state.search.distance,
      lookingFor: selectedGenderId,

      hasPictureOnly: state.search.filteredSearch.hasPictureOnly,
      isCertifiedOnly: state.search.filteredSearch.isCertifiedOnly,
      lastVisitedDate: state.search.filteredSearch.lastVisitedDate,
      selectedPlaceName: state.search.selectedPlaceName,
      lat: state.search.filteredSearch.lat,
      lng: state.search.filteredSearch.lng,
      countryId: state.search.location.countryId,
      cityId: state.search.location.cityId,
      provinceId: state.search.location.provinceId,

      minHeight: state.search.filteredSearch.minHeight,
      maxHeight: state.search.filteredSearch.maxHeight,

      religionId: state.search.filteredSearch.religionId.join(","),
      relationshipType:
        state.search.filteredSearch.relationshipType.join(",") ?? "-2",
      AppPhysiqueId: state.search.filteredSearch.AppPhysiqueId.join(","),
      // cheveuxId: state.search.filteredSearch.cheveuxId.join(","),
      // yeuxId: state.search.filteredSearch.yeuxId.join(","),
      ethnieId: state.search.filteredSearch.ethnieId.join(","),
      signeZodiaqueId: state.search.filteredSearch.signeZodiaqueId.join(","),
      etatCivileId: state.search.filteredSearch.etatCivileId.join(","),
      enfantActuelId: state.search.filteredSearch.enfantActuelId.join(","),
      // enfantDesiresId: state.search.filteredSearch.enfantDesiresId.join(","),
      // occupationId: state.search.filteredSearch.occupationId.join(","),
      // voitureId: state.search.filteredSearch.voitureId.join(","),
      fumeurId: state.search.filteredSearch.fumeurId.join(","),
      // alcoolId: state.search.filteredSearch.alcoolId.join(","),
      // drogueId: state.search.filteredSearch.drogueId.join(","),
    };

    const response = await api.search.getSearch(config);

    return response;
  }
);

export const resetIndex = createAsyncThunk(
  "search/resetIndex",
  async ({}, { getState }) => {
    const state = getState() as RootState;

    if (state.search.location === null) return;

    const selectedGenderId = getGenderId(state.search.selectedGenderName);

    const config = {
      pageIndex: 1,
      pageSize: 32,
      sortBy: state.search.sortBy,
      type: "read" as "read" | "update",
      ageMin: state.search.selectedMinAge,
      ageMax: state.search.selectedMaxAge,
      distance: state.search.distance,
      lookingFor: selectedGenderId,

      hasPictureOnly: state.search.filteredSearch.hasPictureOnly,
      isCertifiedOnly: state.search.filteredSearch.isCertifiedOnly,
      relationshipType:
        state.search.filteredSearch.relationshipType.join(",") ?? "-2",
      minHeight: state.search.filteredSearch.minHeight,
      maxHeight: state.search.filteredSearch.maxHeight,

      religionId: state.search.filteredSearch.religionId.join(","),
      AppPhysiqueId: state.search.filteredSearch.AppPhysiqueId.join(","),
      // cheveuxId: state.search.filteredSearch.cheveuxId.join(","),
      // yeuxId: state.search.filteredSearch.yeuxId.join(","),
      ethnieId: state.search.filteredSearch.ethnieId.join(","),
      signeZodiaqueId: state.search.filteredSearch.signeZodiaqueId.join(","),
      etatCivileId: state.search.filteredSearch.etatCivileId.join(","),
      enfantActuelId: state.search.filteredSearch.enfantActuelId.join(","),
      // enfantDesiresId: state.search.filteredSearch.enfantDesiresId.join(","),
      // occupationId: state.search.filteredSearch.occupationId.join(","),
      // voitureId: state.search.filteredSearch.voitureId.join(","),
      fumeurId: state.search.filteredSearch.fumeurId.join(","),
      // alcoolId: state.search.filteredSearch.alcoolId.join(","),
      // drogueId: state.search.filteredSearch.drogueId.join(","),
      lastVisitedDate: state.search.filteredSearch.lastVisitedDate,
      selectedPlaceName: state.search.selectedPlaceName,
      lat: state.search.filteredSearch.lat,
      lng: state.search.filteredSearch.lng,
      countryId: state.search.location.countryId,
      cityId: state.search.location.cityId,
      provinceId: state.search.location.provinceId,
    };

    const response = await api.search.getSearch(config);

    return response;
  }
);

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    reset: () => initialState,
    setSelectedMinAge: (state, action: PayloadAction<number>) => {
      state.shouldReload = true;
      state.selectedMinAge = action.payload;
    },

    setFetchingMoreResults: (state, action: PayloadAction<boolean>) => {
      state.fetchingResults = action.payload;
    },

    setSelectedMaxAge: (state, action: PayloadAction<number>) => {
      state.shouldReload = true;
      state.selectedMaxAge = action.payload;
    },
    setSelectedGenderName: (state, action: PayloadAction<Gender>) => {
      state.shouldReload = true;
      state.selectedGenderName = action.payload;
    },
    setDistance: (state, action: PayloadAction<number>) => {
      state.shouldReload = true;
      state.distance = action.payload;
    },

    setFilteredSearch: (
      state,
      action: PayloadAction<Partial<FilteredSearch>>
    ) => {
      const key = Object.keys(action.payload)[0] as keyof FilteredSearch;

      if (
        state.filteredSearch[key] === undefined ||
        state.filteredSearch[key] === null
      )
        return;

      if (key === "relationshipType") {
        if (
          action.payload.relationshipType?.length === 1 &&
          (action.payload.relationshipType[0] === "-2" ||
            action.payload.relationshipType[0] === -2)
        ) {
          state.filteredSearch.relationshipType = ["-2"];
          return;
        }

        if (
          action.payload.relationshipType &&
          action.payload.relationshipType?.length > 1 &&
          (action.payload.relationshipType?.includes("-2") ||
            action.payload.relationshipType?.includes(-2))
        ) {
          state.filteredSearch.relationshipType =
            action.payload.relationshipType.filter(
              (x) => x !== "-2" && x !== -2
            );
          return;
        }
      }

      if (
        Array.isArray(state.filteredSearch[key]) &&
        Array.isArray(action.payload[key])
      ) {
        if (action.payload[key].length === 0) return;

        //@ts-ignore
        state.filteredSearch[key] = action.payload[key] as string[];
      } else {
        state.filteredSearch = { ...state.filteredSearch, ...action.payload };
      }
    },

    setLocationFromGoogleApi: (
      state,
      action: PayloadAction<{
        name: string;
        lat: number;
        lng: number;
        countryId: number;
      }>
    ) => {
      state.location = {
        name: action.payload.name,
        type: "country",
        countryId: action.payload.countryId,
        cityId: -1,
        provinceId: -1,
        name_suggestion: action.payload.name,
      };

      state.selectedPlaceName = action.payload.name;

      state.filteredSearch.lat = action.payload.lat;
      state.filteredSearch.lng = action.payload.lng;
    },

    setLocation: (state, action: PayloadAction<LocationHelperPlace>) => {
      if (action.payload.name === "") return;

      state.filteredSearch.lat = -1;
      state.filteredSearch.lng = -1;

      if (
        action.payload.type === "country" ||
        action.payload.type === "region"
      ) {
        state.shouldShowSlider = false;
      } else {
        state.shouldShowSlider = true;
      }

      state.location = action.payload;
    },

    setSortBy(state, action: PayloadAction<"all" | "mostpopular" | "newest">) {
      state.shouldReload = true;
      state.sortBy = action.payload;
    },

    setSelectedPlaceName(state, action: PayloadAction<string>) {
      if (action.payload === null || action.payload === "") return;
      state.selectedPlaceName = action.payload;
    },

    resetFilters(state) {
      state.filteredSearch = {
        lastVisitedDate: 0,
        lat: -1,
        lng: -1,
        hasPictureOnly: 0,
        isCertifiedOnly: 0,

        minHeight: 1,
        maxHeight: 26,
        relationshipType: ["-2"],
        AppPhysiqueId: ["-2"],
        // cheveuxId: ["-2"],
        // yeuxId: ["-2"],
        ethnieId: ["-2"],
        signeZodiaqueId: ["-2"],
        etatCivileId: ["-2"],
        enfantActuelId: ["-2"],
        // enfantDesiresId: ["-2"],
        // occupationId: ["-2"],
        // voitureId: ["-2"],
        fumeurId: ["-2"],
        // alcoolId: ["-2"],
        // drogueId: ["-2"],
        religionId: ["-2"],
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchResults.fulfilled, (state, action) => {
      if (!action.payload) return;

      if (!action.payload.data) {
        Sentry.captureException(
          JSON.stringify({
            action: "fetchResults.fulfilled",
            arg: action.meta.arg,
            payload: action.payload,
          })
        );

        return;
      }

      const reset = action.meta.arg;

      state.pageIndex += 1;
      state.type = "read";
      const hasMore = checkIfHasMore(action.payload.data, state.pageIndex);

      state.hasMore = hasMore;
      state.isLoading = false;
      state.fetchingResults = false;
      if (reset) {
        state.results = action.payload.data.list;
      } else {
        state.results = state.results.concat(action.payload.data.list);
      }

      searchResultsChangedSubject$.next();
    });

    builder.addCase(fetchResults.pending, (state, action) => {
      state.shouldReload = false;

      const reset = action.meta.arg;

      if (reset) {
        state.pageIndex = 1;
        state.results = [];
      }
      state.isLoading = true;
    });

    builder.addCase(fetchResults.rejected, (state, action) => {
      state.isLoading = false;
      state.fetchingResults = false;
      state.error = action.payload as Error; // fix
    });

    builder.addCase(getLoginInfo.fulfilled, (state, action) => {
      if (action.payload.status === "ERROR") return;

      const { searchDefaultParams } = action.payload.data.infosProfile;

      if (Array.isArray(searchDefaultParams)) return;

      state.selectedGenderName = getLookingForSex(
        searchDefaultParams.sexeRechercheId
      );

      state.selectedMaxAge = searchDefaultParams.ageMax;
      state.selectedMinAge = searchDefaultParams.ageMin;
      state.distance = searchDefaultParams.distance;
      state.filteredSearch.hasPictureOnly = +searchDefaultParams.hasPictureOnly;

      state.filteredSearch.isCertifiedOnly =
        +searchDefaultParams.isCertifiedOnly;
      state.filteredSearch.lastVisitedDate = searchDefaultParams.lastVisite;

      state.selectedPlaceName = searchDefaultParams.selectedPlaceName;

      state.filteredSearch.minHeight = Math.max(
        +searchDefaultParams.heightRange[0],
        1
      );
      state.filteredSearch.maxHeight = Math.min(
        searchDefaultParams.heightRange[1] ?? 26,
        26
      );

      state.filteredSearch.AppPhysiqueId = searchDefaultParams.AppPhysiqueId;
      // state.filteredSearch.alcoolId = searchDefaultParams.alcoolId;
      // state.filteredSearch.cheveuxId = searchDefaultParams.cheveuxId;
      // state.filteredSearch.drogueId = searchDefaultParams.drogueId;
      state.filteredSearch.enfantActuelId = searchDefaultParams.enfantActuelId;
      // state.filteredSearch.enfantDesiresId =
      //   searchDefaultParams.enfantDesiresId;
      state.filteredSearch.etatCivileId = searchDefaultParams.etatCivileId;

      state.filteredSearch.ethnieId = searchDefaultParams.ethnieId;

      state.filteredSearch.fumeurId = searchDefaultParams.fumeurId;

      // state.filteredSearch.occupationId = searchDefaultParams.occupationId;

      state.filteredSearch.relationshipType =
        searchDefaultParams.relationshipType;

      state.filteredSearch.religionId = searchDefaultParams.religionId;

      state.filteredSearch.signeZodiaqueId =
        searchDefaultParams.signeZodiaqueId;

      // state.filteredSearch.voitureId = searchDefaultParams.voitureId;

      // state.filteredSearch.yeuxId = searchDefaultParams.yeuxId;

      if (searchDefaultParams.cityId == -1) {
        state.shouldShowSlider = false;
      }

      const locationType =
        searchDefaultParams.countryId !== -1
          ? "country"
          : searchDefaultParams.provinceId == -1
          ? "region"
          : "city";

      state.location = {
        name: searchDefaultParams.selectedPlaceName,
        name_suggestion: searchDefaultParams.selectedPlaceName,
        countryId: searchDefaultParams.countryId,
        cityId: searchDefaultParams.cityId,
        provinceId: searchDefaultParams.provinceId,
        type: locationType,
      };

      state.preferencesLoaded = true;
    });

    builder.addCase(logout.fulfilled, () => initialState);

    builder.addCase(resetIndex.pending, (state) => {
      state.isLoading = true;
      state.pageIndex = 1;
      state.results = [];
    });

    builder.addCase(resetIndex.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.pageIndex += 1;
      state.type = "read";
      const hasMore = checkIfHasMore(action.payload.data, state.pageIndex);

      state.hasMore = hasMore;
      state.isLoading = false;
      state.fetchingResults = false;

      state.results = action.payload.data.list;
    });
  },
});

function getLookingForSex(sexId: string | number): Gender {
  let sexIdStr = typeof sexId === "number" ? sexId.toString() : sexId;

  switch (sexIdStr) {
    case "1":
      return "Man";
    case "2":
      return "Woman";
    case "-1":
      return "Both";
  }

  return "Woman";
}

export const {
  reset,
  setSelectedMinAge,
  setSelectedMaxAge,
  setSelectedGenderName,
  setDistance,
  setFilteredSearch,
  setSortBy,
  resetFilters,
  setLocation,
  setSelectedPlaceName,
  setLocationFromGoogleApi,

  setFetchingMoreResults,
} = searchSlice.actions;

export default searchSlice.reducer;
